
.mcb_learningpath_header {
    padding: 100px 32px;
    background-color: #58b5b7;
    color: #363c5b;
    border: none;
    background-image: url("/icons/Learning-Path-Banner.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
}

.learningpath_header_wrapper {
    display: grid;
    grid-template-columns: 80px auto;
    align-items: center;
}

.learningpath_header_wrapper .module_title {
    text-align: left;
}

.learningpath_header_icon img {
    height: 60px;
}

.mcb_learningpathblocks {
    border-bottom: none;
    background-color: #81C6C8;
    background-image: url("/icons/learningpath-background.png");
    background-position: center;
    background-repeat: repeat-y;
    background-size: 50%;
    display: flex;
    justify-content: center;
}

.learningpath_wrapper {
    float: left;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    max-width: 328px;
}
