.verify_wrapper {
  display: grid;
  justify-items: center;
  align-items: center;
  height: 30vh;
}

.verify {
  width: 500px;
  padding: 28px;
  border-radius: 5px;
  display: grid;
  grid-template-rows: 100px 1fr;
  background-color: #ffffff;
}

.login_logo {
  /* text-align: left; */
}

.login_logo img {
  width: 90%;
}

#btn_login_submit {
  margin-top: 24px;
  float: left;
  padding: 15px 30px !important;
  font-weight: 500 !important;
}

.passwordreset {
  color: #2ea3a6;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
}

.authentication_message {
  margin-top: 24px;
}
