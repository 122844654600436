body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  font-weight: 300;
  color: #141414 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

h1 {
  /* margin: 0 !important;
  padding: 0 !important; */
  font-family: "Poppins", sans-serif !important;

  /*font-weight: 700 !important;*/
  /*font-size: 48px !important;*/
  /*color: #ef6060 !important;*/
  /*padding-bottom: 28px !important;*/
}

h2 {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Poppins", sans-serif !important;

  /*font-weight: 700 !important;*/
  /*font-size: 48px !important;*/
  /*color: #ef6060 !important;*/
  /*padding-bottom: 28px !important;*/
}

h3 {
  font-weight: 700 !important;
  font-size: 24px !important;
  color: #2ea7aa !important;
  font-family: "Poppins", sans-serif !important;
}

p {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  max-width: 800px;
  margin-left: auto !important;
  margin-right: auto !important;
  color: #363c5b;
}

.ui.form.tapform .field.field input:-webkit-autofill,
.ui.form.tapform .field.field input:-webkit-autofill:hover,
.ui.form.tapform .field.field input:-webkit-autofill:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  border-color: #e8e6eb !important;
}

.pagewrapper {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}

@media only screen and (max-width: 768px) {
  .pagewrapper {
    display: grid;
    grid-template-columns: 100vw;
    justify-items: center;
  }
}

.containerwrapper {
  width: 100%;
}

.maincontentblock {
  padding: 48px 32px 48px 32px;
  float: left;
  width: 100%;
  text-align: center;
}

.card_module {
  float: left;
  border: 1px solid #e3e3e3;
  padding: 4px;
  background-color: #ffffff;
  width: calc(50% - 4px);
  cursor: pointer;
  margin: 0px 4px 4px 0px;
}

.card_module_img {
  border: 1px solid #adadad;
  background-color: #dbdbdb;
  width: 150px;
  height: 75px;
  float: left;
}

.card_module_details {
  float: left;
  margin-left: 8px;
  text-align: left;
  width: calc(100% - 160px);
  display: grid;
  height: 74px;
  grid-template-rows: 25px auto 18px;
}

.card_module_details_title {
  float: left;
  color: #ef6060;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 8px;
  width: 100%;
}

.card_module_details_title img {
  float: right;
  height: 24px;
}

.card_module_details_description {
  float: left;
  font-size: 12px;
  line-height: 17px;
  color: #333333;
  width: 100%;
}

.card_module_details_footer {
  font-size: 12px;
  line-height: 18px;
  color: #7d7d7d;
  display: grid;
  float: left;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
}

.card_module_details_footer .footericons {
  float: left;
  margin: 0px 4px 0px 0px;
}

.card_module_details_footer .footericons img {
  height: 18px;
}

.card_asset {
  float: left;
  border: 1px solid #e3e3e3;
  padding: 4px;
  background-color: #ffffff;
  width: calc(25% - 4px);
  cursor: pointer;
  margin: 0px 4px 4px 0px;
}

.card_asset_img {
  height: 50px;
  float: left;
}

.card_asset_img img {
  height: 100%;
}

.card_asset_details {
  float: left;
  margin-left: 8px;
  text-align: left;
  width: calc(100% - 60px);
}

.card_asset_details_title {
  float: left;
  color: #ef6060;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
}

.card_asset_details_description {
  float: left;
  font-size: 12px;
  line-height: 17px;
  color: #333333;
  width: 100%;
}

.btn_primary {
  color: #ffffff;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 600;
  padding: 0px 12px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 26px auto;
  align-items: center;
  background-color: #2ea3a6;
  border: none;
  height: 32px;
}

.btn_primary_icon {
  width: 15px;
  display: flex;
}

.btn_primary_icon img {
  width: 100%;
}

.footer {
  background-color: #363c5b;
  color: #ffffff;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  width: 100%;
}

.footer .logo img {
  width: 33%;
  float: left;
}

.library_tab_wrapper {
  width: 100%;
  color: #bcb6c3;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  display: flex;
  border-bottom: 2px solid #d4d1d8;
}

.tab_wrapper {
  width: 100%;
  color: #bcb6c3;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  display: grid;
  grid-template-columns: auto 85px;
  border-bottom: 2px solid #d4d1d8;
}

.tab_item {
  padding: 0 12px 4px;
  display: flex;
  border-bottom: 2px solid #d4d1d8;
  margin-bottom: -2px;
  cursor: pointer;
}

.tab_wrapper .tab_item {
  padding: 0;
}

.tab_item_img {
  height: 22px;
  margin: -2px 8px 0 0;
  width: 22px;
}

.tab_item_img img {
  width: 100%;
  height: 100%;
}

/* SIGNUP ----------------------------------------------------------------------------------------------------------- */

.mcb_signup {
  background-color: #58b5b7;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.signup_wrapper {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.signup_1 {
  height: 200px;
}

.signup_1 img {
  height: 100%;
}

.signup_2 {
  color: #eb6060;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  align-self: center;
}

.signup_3 {
  color: #bcb6c3;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
}

.signup_3 a {
  color: #eb6060;
  text-decoration: underline;
}

.signup_3_button_wrapper {
  padding: 16px 0;
}

/* TAP CIRCLE SIGNUP ------------------------------------------------------------------------------------------------ */

.tapcircle_signup {
  background-color: #80c6c8;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 0px 0px 16px 0px;
  border-radius: 8px;
  max-width: 750px;
  margin: 0 auto;
}

.tapcircle_signup a {
  color: #363c5b;
  text-decoration: underline;
}

.tapcircle_signup_cta_button {
  padding: 0 36px !important;
  height: 52px !important;
  display: flex;
  justify-content: center;
  text-decoration: none !important;
  margin-bottom: 16px !important;
}

/* LANDING PAGES ---------------------------------------------------------------------------------------------------- */

.mcb_landing_header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #868a9d;
  padding: 0 !important;
}

.landing_title {
  font-weight: 700;
  font-size: 48px;
  display: flex;
  align-items: center;
  padding-left: 32px;
  justify-content: center;
  line-height: 48px;
}

.landing_image img {
  width: 100%;
}

.landing_image_fill {
  background-color: #828699;
}

.mcb_landing_subheader {
  font-weight: 400;
  background-color: #828699;
  color: #ffffff;
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.mcb_landing_subheader p {
  font-weight: 400;
  color: #ffffff;
  text-align: left;
}

.mcb_landing_video iframe {
  margin-bottom: 48px;
}

.landing_video_wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 48px;
}

.landing_video_wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.landing_video {
  background-color: #d4d1d8;
}

.landing_video_description {
  padding-left: 24px;
}

.landing_video_description p {
  text-align: left;
  line-height: 24px;
}

.mcb_landing_whatislearningpath {
  background-color: #f1f0f3;
  padding-bottom: 48px !important;
  background-image: url("/icons/bg pattern 1000px (f1f0f3).svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.whatislearningpath_wrapper {
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
  display: grid;
  grid-template-columns: 244px 1fr;
}

.whatislearningpath_icon {
  padding: 32px;
  margin: 0 auto;
}

.whatislearningpath_icon img {
  width: 180px;
}

.whatislearningpath_icon_temp {
  width: 180px;
  height: 180px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
}

.whatislearningpath_description {
  padding: 40px 16px 40px 0px;
  text-align: left;
}

.whatislearningpath_description_header {
  font-size: 28px;
  font-weight: 600;
  color: #58b5b7;
  padding-bottom: 20px;
}

.whatislearningpath_link {
  background-color: #828699;
  color: #ffffff;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-radius: 0 0 8px 8px;
}

.whatislearningpath_link_text {
  display: flex;
  align-items: center;
  padding-right: 16px;
  font-weight: 400;
}

.mcb_modules {
  border-bottom: none;
  background-color: #f1f0f3;
}

.modules_header {
  color: #868a9d;
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 48px;
}

.modules_wrapper {
  float: left;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}

.module {
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
}

.module_description {
  padding: 19px 16px 16px 0px;
  display: grid;
  grid-template-rows: auto 16px;
  text-align: left;
}

.module_description_wrapper {
  display: grid;
  grid-template-columns: 70px auto;
  height: 100%;
}

.module_description_title {
  color: #eb6060;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  align-self: center;
}

.module_counts_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #bcb6c3;
  font-weight: 500;
  font-size: 12px;
}

.module_description_icon {
  width: 70px;
  padding: 12px 16px;
  display: flex;
}

.module_description_icon img {
  width: 100%;
  height: 100%;
}

/*  MODALS ---------------------------------------------------------------------------------------------------------- */

.modal-header {
  font-size: 19px;
  font-weight: 700;
  color: #4b4f4e;
  border-bottom: 1px solid #e4e8e7;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
  padding: 16px 24px 12px 24px;
}

.modal-content {
  padding: 8px;
}

.modal-footer {
  border-top: 1px solid #e8e9e9;
  height: 56px;
  background-color: #f8f9f9;
  display: grid;
  align-items: center;
}

.modal-footer-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
}

.modal-footer-buttons .ui.button {
  background-color: #ffffff;
  border: 1px solid #e8e9e9;
  font-size: 11px;
  font-weight: 600;
  color: #676c6b;
  padding: 10px 16px 10px 16px;
}

.ui.button.activestate {
  background-color: #2ea3a6;
  border-color: #2ea3a6;
  color: #ffffff;
  font-weight: 700;
}

/*  TAP CIRCLE ------------------------------------------------------------------------------------------------------ */

.tapcircle_tabactive {
  color: #2ea3a6;
}

.tapcircle_title {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #363c5b;
  padding: 16px 0px;
}

.tapcircle_header {
  max-width: 750px;
  margin: 0 auto;
  display: grid;
}

.tapcircle_header_stats {
  padding: 24px 0px 16px 0px;
  display: flex;
}

.tapcircle_header_stats_img {
  width: 20px;
  display: flex;
  margin-right: 12px;
}

.tapcircle_header_stats_img img {
  width: 100%;
  height: 100%;
}

.tapcircle_header_stats_text {
  color: #bcb6c3;
  font-weight: 500;
  font-size: 14px;
  display: flex;
}

.tapcircle_header_comment {
  max-width: 750px;
  margin: 0 auto;
  width: 100%;
}

#btn_tapcircle_submit,
#btn_tapcircle_submitreply,
#btn_journal_submit {
  /* display: flex; */
  margin: 0;
  float: right;
}

.tapcircle_comments {
  max-width: 750px;
  margin: 0 auto;
  width: 100%;
}

.tapcircle_comments_item {
  background-color: #f1f0f3;
  text-align: left;
}

.parentcomment {
  margin-top: 8px;
  border-radius: 8px 8px 0px 0px;
  padding: 16px 16px 0px 16px;
}

.journalcomment {
  margin-top: 8px;
  border-radius: 8px;
  padding: 16px;
}

.replycomment {
  padding: 8px 16px 0px 48px;
}

.tapcircle_comments_item_replyform {
  background-color: #f1f0f3;
  text-align: left;
  border-radius: 0px 0px 8px 8px;
  padding: 0px 16px 16px 48px;
  display: flex;
}

.tapcircle_comments_item_replyform_form {
  padding-top: 16px;
  width: 100%;
}

.tapcircle_comments_item_replyform_form #parentcomment_id {
  display: none;
}

.tapcircle_comments_item_replyform_form .field {
  margin-bottom: 8px !important;
}

.tapcircle_comments_item_member {
  color: #2ea3a6;
  font-weight: 500;
  font-size: 16px;
}

.tapcircle_comments_item_comment {
  font-weight: 400;
  font-size: 14px;
  color: #363c5b;
}

.tapcircle_comments_item_likes {
  display: flex;
}

.tapcircle_comments_item_likes_count {
  color: #bcb6c3;
  font-weight: 500;
  font-size: 14px;
}

.tapcircle_comments_item_likes_icon {
  width: 14px;
  margin-right: 6px;
  cursor: pointer;
}

.tapcircle_comments_item_likes_icon img {
  width: 100%;
  height: 100%;
}

/*  SEMANTIC UI COMPONENTS ------------------------------------------------------------------------------------------ */

.ui.basic.button,
.ui.basic.buttons .button {
  font-family: "Poppins", sans-serif !important;

  border-radius: 18px !important;
}

.ui.primary.button {
  font-family: "Poppins", sans-serif !important;

  border-radius: 4px !important;
  background-color: #2ea3a6 !important;
  color: #ffffff !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  height: 32px;
  padding: 0 12px;
  margin-top: 22px;
}

.ui.primary.button:hover {
  background-color: #237d80 !important;
}

.ui.secondary.button {
  font-family: "Poppins", sans-serif !important;

  border-radius: 4px !important;
  border: 1px solid #2ea3a6 !important;
  background-color: #ffffff !important;
  color: #2ea3a6 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  height: 32px;
  padding: 0 12px;
  margin-top: 22px;
}

.ui.secondary.button:hover {
  color: #237d80 !important;
  border-color: #237d80 !important;
}

.ui.primary.button.btn_primary_ffffff_5e637c {
  background-color: #ffffff !important;
  color: #5e637c !important;
  margin-top: 0px;
}

.ui.primary.button.btn_primary_ffffff_5e637c:hover {
  background-color: #f1f0f3 !important;
}

.ui.primary.button.btn_primary_5e637c_ffffff {
  background-color: #5e637c !important;
  color: #ffffff !important;
  margin-top: 0px;
}

.ui.primary.button.btn_primary_5e637c_ffffff:hover {
  background-color: #5e637c !important;
}

.ui.primary.button.btn_primary_eb6060_ffffff {
  background-color: #eb6060 !important;
  color: #ffffff !important;
  margin-top: 0px;
}

.ui.primary.button.btn_primary_eb6060_ffffff:hover {
  background-color: #ee8080 !important;
}

.ui.primary.button.btn_primary_2ea3a6_ffffff {
  background-color: #2ea3a6 !important;
  color: #ffffff !important;
  margin-top: 0px;
}

.ui.primary.button.btn_primary_2ea3a6_ffffff:hover {
  background-color: #58b5b7 !important;
}

.ui.primary.button.btn_primary_ffffff_2ea3a6 {
  background-color: #ffffff !important;
  color: #2ea3a6 !important;
}

.ui.primary.button.btn_primary_ffffff_eb6060 {
  background-color: #ffffff !important;
  color: #eb6060 !important;
}

.ui.form.tapform .field > label {
  font-family: "Poppins", sans-serif !important;

  font-weight: 500 !important;
  font-size: 14px !important;
  color: #bcb6c3 !important;
  margin: 0 !important;
}

.ui.input > input,
.ui.form.tapform .field.field input,
.ui.input > textarea,
.ui.form.tapform .field.field textarea {
  font-family: "Poppins", sans-serif !important;

  font-size: 14px !important;
  color: #231f20 !important;
  border-color: #e8e6eb !important;
  background-color: #ffffff !important;
}

@media only screen and (min-width: 1065px) {
  .maincontentblock {
    padding: 48px calc((100% - 1000px) / 2) 48px;
  }
}

@media only screen and (max-width: 1065px) {
  .maincontentblock {
    padding: 48px 3.5% 48px 3.5%;
  }
}

@media only screen and (max-width: 950px) {
  .mcb_landing_header {
    grid-template-columns: 1fr;
  }

  .landing_image {
    grid-template-columns: 1fr;
  }

  .mcb_landing_video {
    grid-template-columns: 1fr;
  }

  .landing_video_description {
    padding-left: 0;
    padding-top: 24px;
  }

  .landing_video_description p {
    text-align: center;
  }

  .landing_image {
    grid-template-columns: 1fr;
  }

  .landing_image img {
    width: 100%;
  }

  .landing_title {
    padding: 60px 32px;
  }

  .mcb_landing_subheader {
    padding-top: 16px !important;
  }

  .modules_wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .member {
    text-align: center;
  }

  .mcb_landing_header {
    padding: 80px 32px;
  }

  .landing_title {
    font-size: 40px;
    line-height: 48px;
  }

  .whatislearningpath_wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 244px 1fr;
  }

  .whatislearningpath_icon_temp {
    margin: 0 auto;
  }

  .whatislearningpath_description {
    text-align: center;
    padding: 24px 16px 24px 16px;
  }

  .whatislearningpath_link {
    justify-content: center;
  }

  .module {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    grid-template-rows: 1fr 80px;
  }

  .module_description {
    text-align: left;
    border-right: none;
    padding: 8px 8px 8px 16px;
  }

  .module_description_title {
    line-height: 21px;
    font-size: 17px;
  }

  .module_counts_wrapper {
    text-align: left;
  }
}

@media only screen and (max-width: 615px) {
  .signup_wrapper {
    grid-template-columns: 1fr;
  }

  .modules_wrapper {
    grid-template-columns: 1fr;
  }

  .ui.input > input,
  .ui.form.tapform .field.field input,
  .ui.input > textarea,
  .ui.form.tapform .field.field textarea {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 560px) {
  .modules_wrapper {
    grid-template-columns: 1fr;
  }

  .module {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: unset;
    grid-template-rows: unset;
  }

  .module_description {
    padding: 16px 16px 16px 0px;
    text-align: left;
  }
}

@media only screen and (max-width: 500px) {
  .mcb_landing_header {
    padding: 80px 32px;
  }

  .landing_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 450px) {
  .mcb_landing_header {
    padding: 72px 32px;
  }

  .module {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    grid-template-rows: 1fr 80px;
  }

  .module_description {
    padding: 0px 16px 16px 16px;
    text-align: center;
    border-right: none;
  }

  .whatislearningpath_link {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 8px;
  }
}
