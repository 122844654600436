.tapfooter {
  width: 100%;
  /* display: grid;
  grid-template-rows: 1fr 88px; */
  height: 100%;
}

.tapfooter_upper {
  background-color: #5e637c;
  border: none;
  display: grid;
  grid-template-rows: 180px 1fr;
  color: #ffffff;
  padding: 0 10px;
  text-align: left;
}

.tapfooter_upper_logo {
  display: flex;
  justify-content: left;
  max-width: 400px;
  padding-left: 95px;
}

.tapfooter_upper_links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0px 48px 48px 48px;
}

.tapfooter_upper_links_header {
  font-size: 23px;
  font-weight: 600;
  color: #ffffff;
  padding-bottom: 45px;
  letter-spacing: 0px;
  padding-left: 50px;
}

.tapfooter_upper_links_item {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 45px;
  padding-top: 6px;
  color: #ffffff;
  letter-spacing: 0px;
  padding-left: 50px;
}

.tapfooter_upper_links_details {
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 2px;
}

a .tapfooter_upper_links_details {
  color: #ffffff;
  text-decoration: none;
}

.tapfooter_upper_links_socials_items_wrapper {
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr; */
  padding-left: 50px;
  grid-column-gap: 16px;
}

.tapfooter_upper_links_socials_items {
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 54px;
  height: 54px;
}

.tapfooter_upper_links_socials_items_icon {
  margin: 8px;
}

.tapfooter_upper_links_socials_items_icon img {
  width: 100%;
}

.tapfooter_upper_links_socials_items_text {
  font-size: 16px;
  font-weight: 500;
  color: #363c5b;
}

#txt_footersearch {
  width: 265px;
  height: 38px;
}

.tapfooter_lower {
  background-color: #363c5b;
  border: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  align-content: center;
}

.tapfooter_lower_copyright {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 52px;
  font-size: 18px;
  color: #ffffff;
}

.tapfooter_lower_signup .ui.primary.button {
  background-color: #ffffff !important;
  color: #363c5b !important;
  margin: 0px 8px 0px 8px !important;
}

.tapfooter_lower_links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 32px;
  font-size: 18px;
}

.tapfooter_lower_links a,
.tapfooter_lower_links a:visited {
  color: #ffffff;
  float: left;
}

.tapfooter_lower_links_separator {
  padding: 0px 12px;
  float: left;
}

@media only screen and (max-width: 1200px) {
  .tapfooter {
    grid-template-rows: 1fr auto;
  }

  .tapfooter_upper_links {
    grid-template-columns: 270px 270px;
    grid-row-gap: 48px;
    padding-bottom: 48px;
    justify-content: center;
    text-align: left;
  }

  .tapfooter_lower {
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
    align-content: center;
    justify-content: center;
  }

  .tapfooter_lower_copyright {
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 8px;
    padding-left: 0;
  }

  .tapfooter_lower_links {
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 0;
  }

  #txt_footersearch {
    width: 231px;
  }
}
@media only screen and (max-width: 768px) {
  .tapfooter_upper_links {
    padding: 0 0 48px 0;
  }
}

@media only screen and (max-width: 615px) {
  .tapfooter_upper_links {
    text-align: center;
    grid-template-columns: 1fr;
  }

  .tapfooter_upper_links_socials_items_wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .tapfooter_lower_links_separator {
    padding: 0px 8px;
  }

  .tapfooter_upper_links_header {
    padding-left: 0px;
  }
  .tapfooter_upper_links_item {
    padding-left: 0px;
  }
  .tapfooter_upper_logo {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 450px) {
  .tapfooter_lower_links {
    display: grid;
    grid-template-columns: 1fr;
  }

  .tapfooter_lower_links_separator {
    display: none;
  }
  .tapfooter_upper_links_header {
    padding-left: 0px;
  }

  .tapfooter_upper_links_item {
    padding-left: 0px;
  }

  .tapfooter_upper_logo {
    padding-left: 0px;
  }
}

/* NEW CSS */

.footer-blue-bg {
  background-color: #363b5b;
}

.footer-dblue-bg {
  background-color: #2c314d;
}

@media only screen and (min-width: 768px) {
  .container-padding {
    padding-left: 5%;
    padding-right: 5%;
  }
}
