.login_wrapper {
  display: grid;
  justify-items: center;
  align-items: start;
  height: auto;
  background-color: #f5f5f5;
  width: 100%;
  padding-top: 100px;
  margin-top: 5px;
}

.green_border {
  border: 1px solid #2ea3a6 !important;
}

.login {
  max-width: 400px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  display: grid;
  grid-template-rows: auto;
  background-color: #ffffff;
  min-height: 450px;
  width: 90%;
}

@media only screen and (max-width: 400px) {
  .ui.form .field .ui.input input,
  .ui.form .fields .field .ui.input input {
    width: 80%;
  }
}

.login_logo {
  /* text-align: left; */
}

.btnSign {
}

.login_logo img {
  width: 90%;
}

#btn_login_submit {
  margin-top: 24px;
  float: left;
  padding: 15px 30px !important;
  font-weight: 500 !important;
}

.passwordreset {
  color: #2ea3a6;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
}

.authentication_message {
  margin-top: 24px;
}

@media only screen and (min-width: 768px) {
  .login {
    width: 504px;
  }
}
