




.mcb_404_header {
    padding: 80px 0px 0px 0px;
    background-color: #fafafb;
}

.mcb_404_header img {
    margin-bottom: -6px;
}

#left404 {
    height: 114px;
    padding-right: 80px;
}

#center404 {
    height: 240px;
}

#right404 {
    height: 172px;
    padding-left: 80px;
}

.mcb_404_colorbar {
    background-color: #828699;
    padding: 0px;
    height: 40px;
}

.mcb_404_headertext {
    background-color: #5e637c;
    color: #ffffff;
    text-align: left;
}

.headertext404_header {
    font-size: 90px;
    font-weight: 700;
    line-height: 90px;
}

.headertext404_text {
    font-size: 18px;
    font-weight: 500;
}

.mcb_404_text {
    color: #363c5b;
    text-align: left;
}

.mcb_404_text p {
    max-width: unset;
}

.mcb_404_cta_button {
    padding-top: 24px;
}

@media only screen and (max-width: 1000px) {

    #left404 {
        display: none;
    }

    #right404 {
        display: none;
    }
}

@media only screen and (max-width: 650px) {

    .mcb_404_header {
        padding: 0;
    }

    #center404 {
        width: 330px;
        margin-bottom: -58px;
    }
}