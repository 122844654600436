
#page_library {
    text-align: center;
}

#page_library .tabactive_module {
    color: #eb6060;
}

#page_library .tabactive_block {
    color: #2ea3a6;
}

#page_library .tabactive_topic {
    color: #cfaea9;
}

#page_library .tabactive_lp {
    color: #363c5b;
}

#page_library .tabactive_info {
    color: #5e637c;
}

.signup,
.confirmation {
    text-align: left;
    width: 300px;
}

.signup #signup_expiry {
    width: 80px;
}

.signup #signup_cvc {
    width: 80px;
}

.tab_page {
    padding: 24px 0 0 0;
}

.tab_page_header {
    padding: 16px 0px 32px;
    display: flex;
}

.tab_page_header_img {
    width: 80px;
    margin-right: 16px;
}

.tab_page_header_img img {
    width: 100%;
    height: 100%;
}
.tab_page_header_title {
    font-size: 16px;
    font-weight: 500;
    color: #363c5b;
    display: flex;
    width: 100%;
}

.tab_page_header_description {
    color: #363c5b;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    width: 100%;
    align-items: center;
    text-align: left;
}

.tab_page_module {
    text-align: left;
    padding: 8px 0;
    border-bottom: 1px solid #ECEBEE;
    cursor: pointer;
}

.tab_page_module_title {
    color: #eb6060;
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 4px;
}

.tab_page_module_description {
    color: #363c5b;
    font-size: 13px;
    font-weight: 300;
}

.tab_page_block {
    text-align: left;
    padding: 8px 0;
    border-bottom: 1px solid #ECEBEE;
    cursor: pointer;
}

.tab_page_block_title {
    color: #2ea3a6;
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 4px;
}

.tab_page_block_description {
    color: #363c5b;
    font-size: 13px;
    font-weight: 300;
}

.tab_page_topic {
    text-align: left;
    padding: 8px 0;
    border-bottom: 1px solid #ECEBEE;
    cursor: pointer;
}

.tab_page_topic_title {
    color: #5e637c;
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 4px;
}

.tab_page_topic_breadcrumb {
    display: flex;
}

.tab_page_topic_block_img {
    height: 22px;
    margin: -2px 8px 0 0;
    width: 22px;
}

.tab_page_topic_block_img img {
    width: 100%;
    height: 100%;
}

.tab_page_topic_block {
    color: #bcb6c3;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.tab_page_topic_module_img {
    height: 22px;
    margin: -2px 8px 0 0;
    width: 22px;
}

.tab_page_topic_module_img img {
    width: 100%;
    height: 100%;
}

.tab_page_topic_module {
    color: #bcb6c3;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding-right: 16px;
}

.tab_page_lp {
    text-align: left;
    padding: 8px 0;
    border-bottom: 1px solid #ECEBEE;
    cursor: pointer;
}

.tab_page_lp_title {
    color: #363c5b;
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 4px;
}

.tab_page_lp_description {
    color: #363c5b;
    font-size: 13px;
    font-weight: 300;
}


#tab_page_blocks .block_access.free {
    border-top: 2px solid #ee8080;
    background-color: #ee8080;
}

#tab_page_blocks .block_access_wrapper {
    font-weight: 500;
    font-size: 14px;
    padding: 0 0 8px 0;
    display: flex;
    color: #bcb6c3;
}

#tab_page_blocks .block_access_img {
    width: 20px;
    display: flex;
    margin-right: 4px;
}

#tab_page_blocks .block_access_img img {
    width: 100%;
    height: 100%;
}

#tab_page_blocks .block_access_text.free {
    color: #ffffff;
}

#tab_page_blocks .tab_page_block_title.paid {
    color: #bcb6c3;
}

#tab_page_blocks .tab_page_block_description.paid {
    color: #bcb6c3;
}

#tab_page_topics .block_access.free {
    border-top: 2px solid #ee8080;
    background-color: #ee8080;
}

#tab_page_topics .block_access_wrapper {
    font-weight: 500;
    font-size: 14px;
    padding: 0 0 8px 0;
    display: flex;
    color: #bcb6c3;
}

#tab_page_topics .block_access_img {
    width: 20px;
    display: flex;
    margin-right: 4px;
}

#tab_page_topics .block_access_img img {
    width: 100%;
    height: 100%;
}

#tab_page_topics .block_access_text.free {
    color: #ffffff;
}

#tab_page_topics .tab_page_topic_title.paid {
    color: #bcb6c3;
}

@media only screen and (max-width: 700px) {

    .library_tab_wrapper .tab_item_text {
        display: none;
    }

    .library_tab_wrapper .tab_item {
        padding: 0 8px 4px 0;
    }
}