@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap");
* {
  font-family: "Poppins", sans-serif;
}
.climb {
  transform: translatey(0px);
  -webkit-animation: float 6s ease-in-out infinite;
  animation: float 6s ease-in-out infinite;
}

@-webkit-keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.littlefloat {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: overlay;
}
.textcontainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}
.lineheight13 {
  line-height: 1.3;
}
.brand-red {
  color: #eb6060;
}
.brand-green {
  color: #2ea3a6;
}
@media only screen and (min-width: 1200px) {
  .hero-updated {
    min-height: 858px;
  }
  .mainboxes {
    min-height: 1031px;
  }
  .homecard {
    height: 350px;
    width: 350px;
    border: 1px solid #2ea3a6;
    align-self: center;
  }
  .cardcontainer {
    padding-left: 15%;
    padding-right: 15%;
    grid-template-columns: repeat(3, 1fr);
    justify-self: center;
    row-gap: 80px;
  }
}
.cardcontainer {
  grid-template-columns: repeat(3, 1fr);
  justify-self: center;
  row-gap: 80px;
}

/*whatis tap page*/
.floating_sub_nav {
  background-color: #363c58;
  color: #fff;
}
@media only screen and (max-width: 768px) {
  .whatisit,
  .whatisinit {
    border-bottom: 2px solid #424863;
  }
}

.text-brand-blue {
  color: #424863;
}

@media only screen and (min-width: 1200px) {
  .circleimg {
    max-width: 600px;
    top: -100px;
  }
  .wit_sec_container {
    height: 500px;
  }
  .parentofcircleimg {
    height: 500px;
  }
}
@media only screen and (max-width: 1200px) {
  .parentofcircleimg {
    height: 400px;
  }
  .circleimg {
    max-height: 400px;
  }
}

.max-w-450 {
  max-width: 450px;
}
/* .rotate {
  animation: rotation 150s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
} */
.max-w-600 {
  max-width: 600px;
}
.max-w-650 {
  max-width: 650px;
}
.max-w-700 {
  max-width: 700px;
}
.wit_sec3 .boxes_section .box {
  width: 320px;
  height: 320px;
  border: 1px solid #2ea3a6;
  padding-bottom: 20px;
  border-radius: 10px;
  max-width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 1400px) and (min-width: 835px) {
  .wit_sec3 .boxes_section .box {
    width: 220px;
    height: 220px;
    border: 1px solid #2ea3a6;
    padding-bottom: 20px;
    border-radius: 10px;
    max-width: 100%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1290px) {
  .wit_sec3 .boxes_section .box {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 1400px) and (min-width: 900px) {
  .wit_sec3 .boxes_section .box {
    margin: 20px 50px;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  overflow: hidden;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@media only screen and (min-width: 1200px) {
  .video-sec_wit {
    padding-left: 350px;
    padding-right: 350px;
  }
}

.fourresources {
  margin-top: -100px;
}
.wit_sec3 .boxes_section .boxresources {
  width: 320px;
  height: 320px;
  padding-bottom: 20px;
  border-radius: 10px;
  max-width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 1400px) and (min-width: 835px) {
  .wit_sec3 .boxes_section .boxresources {
    width: 220px;
    height: 220px;
    padding-bottom: 20px;
    border-radius: 10px;
    max-width: 100%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1290px) {
  .wit_sec3 .boxes_section .boxresources {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 1400px) and (min-width: 900px) {
  .wit_sec3 .boxes_section .boxresources {
    margin: 20px 50px;
  }
}

.text-light-gray-brand {
  color: #a7a1ad;
}
.widercards {
  width: 646px;
  height: 222px;
  border: 1px solid #2ea3a6;
  margin: 30px;
}
.widercards_pic {
  width: 25%;
}
.widercards_text {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .widercards_text {
    width: 75%;
  }
}

/*dashboard new*/
.dash_floating1 {
  border-bottom: 1px solid #fff;
}
.mytoolssec {
  background: linear-gradient(#fff, #f5f5f5);
}
.wit_sec3 .boxes_section .dashbox {
  width: 320px;
  height: 320px;
  border: 1px solid #363c58;
  padding-bottom: 10px;
  border-radius: 10px;
  max-width: 100%;
  margin: 30px auto;
  padding-left: 10px;
  padding-right: 10px;
}
.dash-tools-img {
  width: 100px;
  height: auto;
}
.dash-tools-img3 {
  width: 109px;
  height: auto;
}
.dash-tools-img6 {
  height: 100px;
  width: auto;
}
@media only screen and (max-width: 1400px) and (min-width: 835px) {
  .wit_sec3 .boxes_section .dashbox {
    width: 300px;
    height: 300px;
    border: 1px solid #363c58;
    padding-bottom: 10px;
    border-radius: 10px;
    max-width: 100%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 835px) and (min-width: 450px) {
  .wit_sec3 .boxes_section .dashbox {
    width: 280px;
    height: 280px;
    border: 1px solid #363c58;
    padding-bottom: 10px;
    border-radius: 10px;
    max-width: 100%;
    margin: 0 auto;
  }
  .dash-tool-pic {
    padding: 10px;
  }
}

@media only screen and (max-width: 1290px) {
  .wit_sec3 .boxes_section .dashbox {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 1400px) and (min-width: 900px) {
  .wit_sec3 .boxes_section .dashbox {
    margin: 20px 20px;
  }
  .dash-tool-pic {
    padding: 20px;
  }
  .dashbox-text h3 {
  }
}
@media only screen and (max-width: 390px) {
  .wit_sec3 .boxes_section .dashbox {
    width: 250px;
    height: 250px;
    border: 1px solid #363c58;
    padding-bottom: 10px;
    border-radius: 10px;
    max-width: 100%;
    margin: 20px auto;
  }
  .dash-tool-pic {
    padding: 10px;
  }
}
.cta-container {
}
.cta-nb {
  border: 1px solid #2ea3a6;
  max-width: 1200px;
}
@media only screen and (min-width: 1200px) {
  .cta-nb {
    max-height: 251px;
  }
}
.bannerbtn {
  background-color: #2ea3a6;
}

.bannerbtn:hover {
  background-color: #248284;
}

.progress-box {
  min-width: 150px;
  min-height: 150px;
}
@media only screen and (min-width: 800px) {
  .back-fade {
    min-width: 250px;
  }
}
.learning-path-box {
  max-width: 400px;
}
@media only screen and (max-width: 768px) {
  .ctabannercontent {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .back-fade {
    width: 200px;
  }
}
.learning-path-box {
  margin: 20px 10px;
}
.module-border {
  border: 1px solid #e18786;
}
.leftcartoon img,
.rightcartoon img {
  width: auto;
  height: 200px;
}
@media only screen and (max-width: 768px) {
  .leftcartoon img,
  .rightcartoon img {
    width: auto;
    height: 100px;
  }
}

.learning-path-boxes .learning-path-box:nth-child(1) .back-fade {
  background-image: url("/dash/Image-One.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

.learning-path-boxes .learning-path-box:nth-child(2) .back-fade {
  background-image: url("/dash/Image Two.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

.learning-path-boxes .learning-path-box:nth-child(3) .back-fade {
  background-image: url("/dash/Image Three.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
.learning-path-boxes .learning-path-box:nth-child(4) .back-fade {
  background-image: url("/dash/Image Four.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
.learning-path-boxes .learning-path-box:nth-child(5) .back-fade {
  background-image: url("/dash/Image Five.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
.learning-path-boxes .learning-path-box:nth-child(6) .back-fade {
  background-image: url("/dash/Image Six.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
.learning-path-boxes .learning-path-box:nth-child(7) .back-fade {
  background-image: url("/dash/Image Seven.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
.learning-path-boxes .learning-path-box:nth-child(8) .back-fade {
  background-image: url("/dash/Image Eight.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
.learning-path-boxes .learning-path-box:nth-child(9) .back-fade {
  background-image: url("/dash/Image Nine.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

/* ACCOUNT DETAILS */
.account-details-inputs {
  background-color: rgba(46, 163, 166, 0.08);
  border-radius: 8px;
  width: 400px;
}

.tabactive-account-details {
  color: #2ea3a6;
}

.account-tab-titles {
  padding: 10px 20px;
}

.cancel-button {
  color: #eb6160;
}

.learingpathsec-1 {
  background-image: url("/dash/textureBgGreen.png");
}

.tabactive-dashboard-header {
  background-color: #5e637c;
}
