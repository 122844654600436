
#mcb_wialp {
    background-color: #f1f0f3;
    text-align: left;
}

#mcb_wialp_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 0px;
}

.wialp_1 {
    background-color: #80C6C8;
    background-image: url("/icons/wialp_bg.png");
    background-position: center;
    background-repeat: repeat-y;
    background-size: 70%;
}

.wialp_2 {
    padding: 48px 0px 0px 48px;
}

.wialp_2_item {
    margin-bottom: 48px;
}

.wialp_2_item_img {
    height: 80px;
}

.wialp_2_item_img img{
    width: 100%;
    height: 100%;
}

.wialp_2_text {
    padding-top: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #363c5b;
}

.wialp_2_separator {
    padding-top: 48px;
    display: grid;
    grid-template-columns: 25% 50% 25%;
}

.wialp_2_separator_border {
    border-bottom: 2px solid #f1f0f3;
}

@media only screen and (max-width: 715px) {


    #mcb_wialp_wrapper {
        grid-template-columns: 1fr;
    }

    .wialp_1 {
        display: none;
    }

    .wialp_2 {
        padding: 48px;
    }

}