.mcb_home_features_intro {
  background-color: #2ea3a6;
  color: #ffffff;
}

.mcb_home_features_intro p {
  color: #ffffff;
  font-weight: 500 !important;
  text-align: left;
}

.mcb_home_features {
  background-color: #80c6c8;
}

.home_features_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.home_features_item {
  padding: 32px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  height: 100%;
}

.home_features_item_title {
  color: #5e637c;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  align-self: center;
  padding-bottom: 24px;
}

.home_features_item_icon {
  padding: 18px 0px 40px 0px;
  display: flex;
  justify-content: center;
}

.home_features_item_icon img {
  width: 125px;
}

.home_features_item_text {
  color: #a7a1ad;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.home_features_item_text span {
  font-weight: 600;
  color: #5e637c;
}

@media only screen and (max-width: 950px) {
  .home_features_wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 615px) {
  .home_features_wrapper {
    grid-template-columns: 1fr;
  }
}
