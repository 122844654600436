#page_signup {
  display: flex;
  /* grid-template-columns: 3fr 1fr;
  grid-column-gap: 16px; */
  padding: 0px;
}
.signup_new {
  max-width: 400px;
  margin-top: 5px;
}
#page_signup_new {
  display: flex;
  /* grid-template-columns: 3fr 1fr;
  grid-column-gap: 16px; */
  padding: 0px;
  flex-wrap: wrap;
}

.signup_container_new {
  display: flex;
  /* grid-template-columns: 3fr 1fr;
  grid-column-gap: 16px; */
  padding: 0px;
}

.page_signup_1 {
  /* border-radius: 8px;
  background-color: #fff;
  padding: 24px 0; */
  border-radius: 8px;
  background-color: #fff;
  padding: 30px;
  /* width: 70%; */
  display: flex;
  justify-content: center;
}

.signup_box {
  display: flex;
  flex-direction: column;

  max-width: 400px;
}

.page_signup_1 .page_signup_header {
  padding-left: 12px;
  padding-bottom: 0;
}

.page_signup_2 {
  background-color: #f5f5f5;
  /* width: 30%; */
  padding: 30px;
}

.page_signup_header {
  font-weight: 700;
  font-size: 24px;
  line-height: 18px;
  padding-bottom: 24px;
  text-align: left;
}

.page_signup_header_new {
}

.page_signup_2_wrapper {
  display: grid;
  /* grid-template-columns: 300px; */

  grid-column-gap: 16px;
  grid-row-gap: 24px;
}

.page_signup_2_item_header {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #313131;
  opacity: 1;
  text-align: left;
  padding-bottom: 8px;
  letter-spacing: 0px;
}

.page_signup_2_item_text {
  color: #bcb6c3;
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.signup {
  width: 100%;
  margin: 32px auto;
}

.passwordnote {
  margin-top: 32px;
  margin-bottom: 16px;
  color: #bcb6c3;
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.passwordvalidation {
  margin-bottom: 32px;
}

.ui.form.tapform .field > label {
  color: #313131 !important;
}

.ui.form .field .ui.input,
.ui.form .fields .field .ui.input,
.ui.form .wide.field .ui.input {
  height: 50px;
}

@media only screen and (max-width: 950px) {
  #page_signup {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }

  .page_signup_2_wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .page_signup_2 {
    position: relative;
  }
}

@media only screen and (max-width: 615px) {
  .page_signup_2_wrapper {
    grid-template-columns: 1fr;
  }

  .page_signup_2 {
    position: relative;
  }
}

.feature-check-mark {
  height: 24px;
  width: 24px;
  margin-right: 16px;
}
