/*whatis tap page*/
.floating_sub_nav {
  background-color: #363c58;
  color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

@media only screen and (max-width: 768px) {
  .floating_sub_nav {
    display: none;
  }
}

.sus-border {
  border: 1px solid #2ea3a6 !important;
}

.sus-pic {
  width: 150px;
  height: 150px;
}

.sus-parent-box {
  /* width: 34%; */
}
@media only screen and (min-width: 768px) {
  .learning-path-box2 {
    max-width: 500px;
    margin: 20px 40px;
  }
}

.learning-path-box2 {
  margin: 20px 40px;
}

.floating_1:hover,
.floating_2:hover,
.floating_3:hover {
  background-color: #4a4f68;
}
@media only screen and (max-width: 768px) {
  .whatisit,
  .whatisinit {
    border-bottom: 2px solid #424863;
  }
}

.text-brand-blue {
  color: #424863;
}

@media only screen and (min-width: 1200px) {
  .circleimg {
    max-width: 600px;
    top: -100px;
  }
  .wit_sec_container {
    height: 500px;
  }
  .parentofcircleimg {
    height: 500px;
  }
}
@media only screen and (max-width: 1200px) {
  .parentofcircleimg {
    height: 400px;
  }
  .circleimg {
    max-height: 400px;
  }
}

.max-w-450 {
  max-width: 450px;
}
/* .rotate {
  animation: rotation 150s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
} */
.max-w-600 {
  max-width: 600px;
}
.max-w-650 {
  max-width: 650px;
}
.max-w-700 {
  max-width: 700px;
}
.wit_sec3 .boxes_section .box {
  width: 320px;
  height: 320px;
  border: 1px solid #2ea3a6;
  padding-bottom: 20px;
  border-radius: 10px;
  max-width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 1400px) and (min-width: 835px) {
  .wit_sec3 .boxes_section .box {
    width: 220px;
    height: 220px;
    border: 1px solid #2ea3a6;
    padding-bottom: 20px;
    border-radius: 10px;
    max-width: 100%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1290px) {
  .wit_sec3 .boxes_section .box {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 1400px) and (min-width: 900px) {
  .wit_sec3 .boxes_section .box {
    margin: 20px 50px;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  overflow: hidden;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@media only screen and (min-width: 1200px) {
  .video-sec_wit {
    padding-left: 350px;
    padding-right: 350px;
  }
}

.fourresources {
  margin-top: -100px;
}
.wit_sec3 .boxes_section .boxresources {
  width: 320px;
  height: 320px;
  padding-bottom: 20px;
  border-radius: 10px;
  max-width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 1400px) and (min-width: 835px) {
  .wit_sec3 .boxes_section .boxresources {
    width: 220px;
    height: 220px;
    padding-bottom: 20px;
    border-radius: 10px;
    max-width: 100%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1290px) {
  .wit_sec3 .boxes_section .boxresources {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 1400px) and (min-width: 900px) {
  .wit_sec3 .boxes_section .boxresources {
    margin: 20px 50px;
  }
}

.text-light-gray-brand {
  color: #a7a1ad;
}
.widercards {
  width: 646px;
  height: 222px;
  border: 1px solid #2ea3a6;
  margin: 30px;
}
.widercards_pic {
  width: 25%;
}
.widercards_text {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .widercards_text {
    width: 75%;
  }
}

.tabactive-what-header {
  background-color: #5e637c;
}
