.mcb_blogs_header {
  background-color: #cfaea9;
  padding: 80px 0;
}

.blogs_title {
  font-weight: 700;
  font-size: 52px;
  color: #ffffff;
  line-height: 52px;
  padding: 0 8px;
}

.mcb_blogs {
  background-color: #f1f0f3;
}

.blogs_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.blog {
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  display: grid;
  grid-template-rows: auto auto 38px;
}

.blog_img img {
  /* margin: 16px; */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.blog_img img {
  width: 100%;
  margin-bottom: -6px;
}

.blog_description {
  padding: 0px 16px 16px 16px;
  text-align: left;
  display: grid;
  grid-template-rows: 50px 1fr;
  /* display: flex;
    flex-direction: column; */
  border-bottom: 2px solid #f1f0f3;
}

.blog_description_title {
  color: #2ea3a6;
  font-weight: 500;
  font-size: 18px;
  align-self: center;
  line-height: 19px;
}

.blog_description_text {
  color: #a7a1ad;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.blog_details {
  text-align: left;
  color: #bcb6c3;
  font-weight: 500;
  font-size: 12px;
  padding: 4px 16px 4px 16px;
  line-height: 14px;
}

@media only screen and (max-width: 1000px) {
  .blogs_wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 750px) {
  .blogs_wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 500px) {
  .blogs_wrapper {
    grid-template-columns: 1fr;
  }
}

.text-cutoff {
  text-overflow: ellipsis; /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.blog-description-new {
  padding: 0px 16px 16px 16px;
  text-align: left;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #f1f0f3;
  margin-top: 14px;
  margin-bottom: 12px;
}

.blogs_wrapper-new {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.blog-new {
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 10px;
}

.blog_description_title-new {
  color: #2ea3a6;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 640px) {
  .blog-new {
    width: 80%;
  }
}
