.mcb_blog_header {
    background-color: #ffffff;
    padding: 80px 0 0 0;
}

.mcb_blog_content {
    padding-top: 0px;
}

.blog_title {
    font-weight: 700;
    font-size: 52px;
    color: #424863;
    line-height: 52px;
}

.blog_content {
    max-width: 1000px;
    padding: 0 48px 48px 48px;
    text-align: left;
}

.blog_author {
    color: #a7a1ad;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
}

.mcb_blog_author {
    background-color: #f1f0f3;
}

.blog_author_wrapper {
    background-color: #ffffff;
    border-radius: 7px;
    display: grid;
    grid-template-columns: 200px 1fr;
}

.blog_author_avatar {
    padding: 20px;
}

.blog_author_avatar_img {
    width: 160px;
    height: 160px;
    border: 1px solid #f1f0f3;
    border-radius: 80px;
}

.blog_author_details {
    text-align: left;
    padding: 20px 20px 20px 0px;
}

.blog_author_details_name {
    color: #2ea3a6;
    font-size: 18px;
    font-weight: 500;
}

.blog_author_details_title {
    color: #363c5b;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    padding-bottom: 18px;
}

.blog_author_details_bio {
    color: #a7a1ad;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
}


.blog_author_avatar_img img {
    height: 100%;
    width: 100%
}

@media only screen and (max-width: 500px) {

    .blog_author_wrapper {
        grid-template-columns: 1fr;
    }

    .blog_author_avatar_img {
        margin: 0 auto;
    }

    .blog_author_details {
        padding: 20px;
        text-align: center;
    }
}