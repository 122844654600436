
.mcb_page_breadcrumb {
    padding-bottom: 0px;
}

.page_header_breadcrumb {
    color: #bcb6c3;
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    display: flex;
}

.page_header_breadcrumb_module {
    cursor: pointer;
    display: flex;
}

.page_header_breadcrumb_block_img {
    height: 22px;
    margin: -2px 4px 0px 4px;
}

.page_header_breadcrumb_block {
    display: flex;
}

.page_header_breadcrumb_block_img img {
    height: 100%;
}

.mcb_page_header {
    padding-bottom: 0px;
    color: #2ea3a6;
    border: none;
    display: grid;
    grid-template-columns: 80px auto;
    align-items: center;
}

.mcb_content p {
    max-width: 90%;
    text-align: left;
    margin: 0 auto 1em auto;
    line-height: 24px;
}

.page_header_icon {
    display: flex;
}

.page_header_icon img {
    width: 100%;
    height: 100%;
}

.page_header_title {
    display: flex;
    font-weight: 700;
    font-size: 52px;
    font-family: "Heebo", sans-serif;
    line-height: 60px;
    color: #5e637c;
    padding: 0 0 0 12px !important;
    text-align: left;
}

#contentwrapper {
    display: grid;
    grid-template-columns: auto;
    padding: 0px;
    justify-items: center;
}

.page_header_img {
    margin-top: 48px;
    margin-bottom: 96px;
    width: 100%;
}

.page_header_img img {
    width: 100%;
    height: 100%;
}

#content_description {
    max-width: 1000px;
    padding: 48px;
    text-align: left;
}



.resource_viewer_wrapper {
    display: grid;
    grid-template-rows: 80px auto 44px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 8px;
}

.resource_viewer_wrapper_mp4 {
    grid-template-rows: 80px auto !important;
}

.resource_viewer_details {
    display: grid;
    grid-template-rows: 1fr 5px 1fr;
    padding-left: 16px;
}

.resource_viewer_details_top {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.resource_viewer_header {
    border-bottom: 2px solid #f1f0f3;
    padding: 12px 0px;
    display: grid;
    grid-template-rows: 40px 16px;
}

.resource_viewer_title {
    color: #5e637c;
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    line-height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resource_viewer_stats_wrapper {
    display: flex;
    justify-content: center;
}

.resource_viewer_stats {
    color: #bcb6c3;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    line-height: 15px;
}

.resource_viewer_stats2 {
    color: #bcb6c3;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    line-height: 15px;
    margin-left: 8px;
}


.resource_viewer_footer {
    display: grid;
    border-top: 2px solid #f1f0f3;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "viewerPages viewerNavigation viewerDownload";
}

.resource_viewer_footer_pages {
    grid-area: viewerPages;
    color: #bcb6c3;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 16px;
}

.resource_viewer_footer_pages_icon {
    width: 20px;
    display: flex;
    margin-right: 12px;
    cursor: pointer;
}

.resource_viewer_footer_pages_icon img {
    width: 100%;
    height: 100%;
}

.resource_viewer_footer_navigation {
    grid-area: viewerNavigation;
    display: flex;
    justify-self: center;
    align-items: center;
}

.resource_viewer_footer_download {
    grid-area: viewerDownload;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 6px;
}


.resource_viewer_details_bottom {
    color: #bcb6c3;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    padding-top: 24px;
}

#btn_download {
    margin: 0;
}

.pdf_viewer {
    /*min-height: 350px;*/
    background-color: #ffffff;
    /*margin: 16px 8px 16px 16px;*/
}

.pdf_viewer_controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 8px;
}

.pdf_viewer_controls_info {
    color: #bcb6c3;
    font-weight: 500;
    font-size: 12px;
    text-align: left;
}

.pdf_viewer_controls_btns {
    display: flex;
    margin-left: auto;
}

.pdf_viewer_controls_btn {
    color: #2ea7aa;
    font-weight: 500;
    font-size: 14px;
    padding-top: 4px;
    cursor: pointer;
}

.pdf_viewer_controls_btn:first-of-type {
    margin-right: 12px;
}

.pdf_viewer_controls_btn.page_inactive {
    color: #E3E2E4;
}

.pdf_viewer_controls_btn.page_active {
    color: #2ea7aa;
}

/*  topic ----------------------------------------------------------------------------------------------------------- */

.mcb_topics {
    border-bottom: none;
    background-color: #f1f0f3;
}

.topics_wrapper {
    float: left;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.topic {
    background-color: #ffffff;
    border-radius: 8px;
    display: grid;
    grid-template-rows: 78px 44px;
}

.topic_description_wrapper {
    display: grid;
    grid-template-columns: 70px auto;
    border-bottom: 2px solid #f1f0f3;
}

.topic_description_icon {
    width: 70px;
    padding: 12px 16px;
    display: flex;
}

.topic_description_icon img {
    width: 100%;
    height: 100%;
}

.topic_description {
    padding: 19px 16px 16px 0px;
    display: grid;
    grid-template-rows: 21px 16px;
    text-align: left;
}

.topic_description_title {
    color: #5e637c;
    font-weight: 700;
    font-size: 18px;
}

.topic_description_views {
    color: #bcb6c3;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    flex-direction: row;
}

.topic_description_views_CSAS {
    color: #2ea7aa;
    padding-right: 4px;
}

.topic_resources {
    color: #bcb6c3;
    font-weight: 500;
    font-size: 14px;
    display: grid;
    align-items: center;
    grid-template-columns: 120px auto;
}

.topic_resources_CSAS {
    display: flex;
    flex-direction: row;
    padding: 6px;
}

.topic_resources_CSAS a,
.topic_resources_CSAS .block_topic_right_resources_btn {
    width: calc(50%);
}


.topic_resources_CSAS a .block_topic_right_resources_btn {
    width: 100%;
}

.topic_resource_buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.topic_resource_buttons_cs {
    grid-template-columns: minmax(100px, 33.3%) minmax(120px, 1fr);
}

.topic_resources_header {
    color: #bcb6c3;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 15px;
    padding: 0 0 0 16px;
}

.topic_resources_items {
    color: #bcb6c3;
    font-weight: 500;
    font-size: 14px;
    display: grid;
}

.topic_resources_cs {
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    color: #ffffff;
    border: 1px solid #2ea7aa;
    background-color: #2ea7aa;
    padding: 0px 6px;
    cursor: pointer;
    display: grid;
    align-items: center;
    height: 32px;
    min-width: 32px;
    margin-left: 6px;
}

.block_topic_right_resources_btn {
    color: #ffffff;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    padding: 0px 12px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 26px auto;
    align-items: center;
    height: 32px;
    margin-right: 6px;
}

.block_topic_right_resources_btn_icon {
    width: 15px;
    display: flex;
}

.block_topic_right_resources_btn_icon img {
    width: 100%;
    height: 100%;
}

.resource_active {
    background-color: #5e637c;
    cursor: pointer;
}

.resource_inactive {
    background-color: #f1f0f3;
    cursor: auto;
}

.resource_complete {
    background-color: #9C9FAE;
}

.resource_new {
    background-color: #52C126 !important;
}

.topic_legend_wrapper {
    width: 100%;
    display: flex;
    padding: 8px 0;
}

.topic_legend {
    color: #c4c1c8;
    font-size: 13px;
    font-weight: 600;
    padding: 0 12px 0 0;
    display: grid;
    grid-template-columns: 20px auto;
    align-items: center;
}

.topic_legend_icon {
    width: 15px;
    display: flex;
}

.topic_legend_icon img {
    width: 100%;
    height: 100%;
}

#mdl_CS {
    width: 300px;
    padding: 32px;
}

#mdl_AS {
    width: 300px;
    padding: 32px;
}

.mdl_title {
    color: #5e637c;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
}

.mdl_icon {
    padding: 24px;
    text-align: center;
}

.mdl_icon img {
    width: 60px;
}

.mdl_description {
    color: #5e637c;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    line-height: 1.4285em;
}

@media only screen and (max-width: 900px) {

    .topic {
        grid-template-rows: 100px 44px;
    }

    .topic_CSAS {
        grid-template-rows: 100px 44px !important;
    }

    .topic_description {
        grid-template-rows: 42px 16px;
    }
}



@media only screen and (max-width: 860px) {
    .topic {
        grid-template-rows: 100px 72px;
    }

    .topic_CSAS {
        grid-template-rows: 100px 44px !important;
    }

    .topics_wrapper {
        grid-template-columns: 1fr;
    }

    .topic_description {
        border-right: none;
    }

    .topic_description_title {
        line-height: 21px;
        font-size: 17px;
    }

    .topic_resources {
        grid-template-rows: 26px 44px;
        grid-template-columns: none;
    }

    .topic_resource_buttons {
        padding-left: 8px;
    }

    .topic_resources_header {
        border-bottom: none;
        font-size: 13px;
        padding: 4px 0px 0px 0px;
        justify-content: center;
    }

    .topic_resources_items {
        padding: 6px;
    }

}

@media only screen and (max-width: 768px) {

    .mcb_page_header {
        padding: 80px 32px 0px;
        grid-template-columns: 66px auto;
    }

    .page_header_title {
        font-size: 40px;
        line-height: 48px;
    }

    .page_header_img {
        margin-bottom: 80px;
    }

    .resource_viewer_details {
        padding-left: 0px;
        padding-bottom: 20px;
    }

    .resource_viewer_details_top_title {
        padding-bottom: 22px;
    }

    .resource_viewer_details_spacer {
        grid-template-columns: 1fr 2fr 1fr;
    }

    .resource_viewer_details_bottom {
        padding-top: 12px;
    }

    #btn_download {
        margin-top: 0px;
    }

    .pdf_viewer {
        margin: 16px 2%;
    }

}





@media only screen and (max-width: 620px) {

    .topics_wrapper {
        grid-template-columns: 1fr;
    }


    .topic {
        grid-template-rows: 78px 44px;
        grid-template-columns: unset;
    }

    .topic_CSAS {
        grid-template-rows: 100px 44px !important;
    }

    .topic_description {
        padding: 19px 16px 16px 0px;
        display: grid;
        grid-template-rows: 21px 16px;
        text-align: left;
    }

    .topic_resources {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: none;
    }

    .topic_resources_header {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 0 16px;
        justify-content: flex-start;
    }

    .topic_resources_items {
        padding: 6px;
    }

}





@media only screen and (max-width: 500px) {

    .mcb_page_header {
        padding: 80px 32px 0px;
    }

    .page_header_breadcrumb {
        display: grid;
        grid-template-rows: 28px 20px;
    }

    .page_header_title {
        font-size: 32px;
        line-height: 36px;
    }

    .page_header_img {
        margin-bottom: 72px;
    }

    .resource_viewer_details_top_title {
        line-height: 28px;
        padding: 12px 0px 12px 0px;
    }

    .resource_viewer_wrapper {
        grid-template-rows: 96px auto 80px;
    }

    .resource_viewer_wrapper_mp4 {
        grid-template-rows: 96px auto !important;
    }

    .resource_viewer_header {
        grid-template-rows: 56px 16px;
    }

    .resource_viewer_footer {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "viewerNavigation viewerNavigation"
                             "viewerPages viewerDownload";
        grid-template-rows: 41px 30px;
    }

    .resource_viewer_footer_navigation {
        border-bottom: none;
    }

    .resource_viewer_footer_pages {
        border-bottom: none;
        border-right: none;
    }

    #btn_download {
        margin-top: 0px;
    }

    .resource_viewer_footer_download {
        border-right: none;
        border-bottom: none;
    }

}





@media only screen and (max-width: 450px) {

    .mcb_page_header {
        padding: 72px 32px 0px;
    }

    .page_header_img {
        margin-top: 0;
        margin-bottom: 48px;
    }

    .topic {
        grid-template-rows: 100px 44px;
    }

    .topic_description {
        grid-template-rows: 42px 16px;
    }

}





@media only screen and (max-width: 380px) {

    .topic {
        grid-template-rows: 100px 72px;
    }

    .topic_description {
        border-right: none;
    }

    .topic_description_title {
        line-height: 21px;
        font-size: 17px;
    }

    .resource_viewer_wrapper {
        grid-template-rows: 96px auto 84px;
    }

    .resource_viewer_wrapper_mp4 {
        grid-template-rows: 96px auto !important;
    }

    .resource_viewer_footer {
        grid-template-rows: 41px 30px;
    }

    .topic_resources {
        grid-template-rows: 26px 44px;
        grid-template-columns: none;
    }

    .topic_resources_header {
        border-bottom: none;
        font-size: 13px;
        padding: 4px 0px 0px 0px;
    }

    .topic_resources_items {
        padding: 6px;
    }

}