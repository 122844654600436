/*  HEADER ---------------------------------------------------------------------------------------------------------- */

.header {
  display: grid;
  grid-template-columns: 240px 1fr 300px;
  width: 100%;
  padding: 24px 10%;
  border-bottom: 1px solid #e3e3e3;
}

.header .logo {
  /* height: 55px;
  margin: -10px 0px -10px 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header .login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-signed-out {
  display: grid;
  grid-template-columns: 240px 1fr 300px;
  width: 100%;
  padding: 24px 10%;
  border-bottom: 1px solid #e3e3e3;
}

.header-signed-out .logo {
  /* height: 55px;
  margin: -10px 0px -10px 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-signed-out .login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu {
  display: flex;
  justify-content: flex-end;
}

.menuitem {
  font-family: "Poppins", sans-serif !important;
  border-radius: 18px !important;
  padding: 8px 16px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
}

.member {
  font-family: "Poppins", sans-serif !important;
  border-radius: 18px !important;
  padding: 8px 16px;
  /* font-weight: 500; */
  color: #bcb6c3;
  cursor: pointer;
}

.hamburgermenu {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.hamburgermenu-panel {
  position: absolute;
  top: 70px;
  z-index: 200;
  background-color: #ffffff;
  width: 100%;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e3e3e3;
}

.hamburgermenu-panel .menuitem {
  display: flex;
  justify-content: center;
}

#member_signedout {
  display: flex;
  justify-content: space-around;
}

#member_signedout_signup {
  margin-right: 6px;
}

#member_signedin {
  display: grid;
  grid-template-rows: 17px 12px;
}

#member_item_name {
  color: #2ea3a6;
  font-weight: 500;
}

#member_item_signout {
  font-size: 13px;
  font-weight: 400;
}

/*  HEADER SIGN IN -------------------------------------------------------------------------------------------------- */

.signin {
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid #e3e3e3;
}

.signin #frm_signin {
  display: flex;
  justify-content: flex-end;
}

.signin #signin_email {
  width: 280px;
}

.signin #signin_password {
  width: 160px;
  margin-right: 16px;
}

.member_signedin_new {
  display: flex !important;
  align-items: center !important;
}

.member_item_new {
  margin-left: 20px !important;
  background-color: #2ea3a6 !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;
  color: #ffffff;
}

@media only screen and (min-width: 1052px) {
  .hamburgermenu {
    display: none;
  }

  .hamburgermenu-panel {
    display: none;
  }
}

@media only screen and (max-width: 1051px) {
  .header {
    grid-template-columns: 240px 1fr 40px;
  }

  .header .menu {
    display: none;
  }

  .header .member {
    display: none;
  }
}

@media only screen and (max-width: 1051px) {
  .header-signed-out {
    grid-template-columns: 240px 1fr 40px;
  }

  .header-signed-out .menu {
    display: none;
  }

  .header-signed-out .member {
    display: none;
  }
}

@media only screen and (min-width: 1051px) {
  .member {
    font-family: "Poppins", sans-serif !important;
    border-radius: 18px !important;
    padding: 8px 16px;
    /* font-weight: 500; */
    color: #bcb6c3;
    cursor: pointer;
    display: flex !important;
    justify-content: flex-end !important;
  }
}

@media only screen and (max-width: 1494px) {
  .header {
    padding: 24px 50px;
  }
}

@media only screen and (max-width: 1300px) {
  .header {
    grid-template-columns: 200px 1fr 200px;
    padding: 24px 50px;
  }
}

@media only screen and (max-width: 1140px) {
  .header {
    padding: 14px 10px;
  }
}

@media only screen and (max-width: 1264px) {
  .header-signed-out {
    padding: 24px 10px;
  }
}
