.tab_page_header_title {
  font-size: 16px;
  font-weight: 500;
  color: #363c5b;
  display: flex;
  width: 100%;
}

#tab_page_dashboard {
  padding: 0px;
}

.tab_item_account {
  display: flex;
  justify-content: flex-end;
}

#member .tabactive {
  color: #2ea3a6;
}

#tab_page_dashboard .mcb_home_features {
  padding: 48px 16px;
}

#tab_page_dashboard .mcb_home_features_intro {
  color: #ffffff;
  font-weight: 700;
  font-size: 24px;
  text-align: left;
  padding: 32px;
}

#tab_page_dashboard .home_features_item_text {
  text-align: center;
}

.tab_page_dashboard_name {
  background-color: #2ea3a6;
  color: #ffffff;
  font-weight: 600;
  font-size: 32px;
  padding: 32px;
  line-height: 34px;
}

.tab_page_dashboard_header {
  padding: 24px;
}

.tab_page_dashboard_header_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #363c5b;
}

.tab_page_dashboard_header_text {
  color: #a7a1ad;
  font-size: 14px;
  font-weight: 400;
}

.tab_page_dashboard_content {
  background-color: #f1f0f3;
  padding: 16px;
}

.tab_page_dashboard_content_header {
  color: #eb6060;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 16px;
}

.tab_page_dashboard_content_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.tab_page_dashboard_content_item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
}

.tab_page_dashboard_content_item_title {
  color: #2ea3a6;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  align-self: center;
}

.tab_page_dashboard_content_item_text {
  color: #bcb6c3;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.tab_page_dashboard_footer {
  display: grid;
  grid-template-columns: auto 450px auto;
  padding-top: 48px;
}

.tab_page_dashboard_footer_1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tab_page_dashboard_footer_1 img {
  height: 90px;
}

.tab_page_dashboard_footer_2 {
  font-weight: 600;
  font-size: 29px;
  padding: 48px;
  color: #a7a1ad;
  display: flex;
  justify-content: center;
}

.tab_page_dashboard_footer_3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tab_page_dashboard_footer_3 img {
  height: 90px;
}

.learningpath_description_title {
  color: #2ea3a6;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
}

.learningpath_description_wrapper {
  display: grid;
  grid-template-columns: 70px auto;
}

.learningpath_description {
  text-align: left;
  display: grid;
  grid-template-rows: 40px auto;
  align-items: self-end;
}

.learningpath_description_icon {
  width: 70px;
  padding: 8px 16px 8px 0px;
  display: flex;
}

.learningpath_description_icon img {
  width: 100%;
  height: 100%;
}

.tab_page_account_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.passwordvalidation_note {
  color: #363c5b;
  font-weight: 300;
  font-size: 13px;
  line-height: 14px;
  padding-bottom: 24px;
}

.passwordvalidation {
  color: #363c5b;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
}

.passwordvalidation_item {
  display: flex;
  padding-bottom: 2px;
}

.passwordvalidation_item_icon {
  width: 16px;
  margin-right: 6px;
  margin-top: -3px;
}

.passwordvalidation_item_icon img {
  width: 100%;
  height: 100%;
}

.inactivestate {
  opacity: 0.5;
}

.passwordvalidation_message {
  margin-top: 24px;
}

/*  MODAL: UPDATE CREDIT CARD ----------------------------------------------------------------------------------------*/

#mdl_updatecreditcard {
  width: 500px;
}

#mdl_updatecreditcard .modal-header {
  color: #2ea3a6;
}

#mdl_updatecreditcard .modal-header img {
  height: 26px;
  margin: -10px 8px -7px -14px;
}

#mdl_updatecreditcard .modal-content {
  padding: 24px;
  line-height: 20px;
}

/*  MODAL: CANCEL MEMBERSHIP -----------------------------------------------------------------------------------------*/

#mdl_cancelmembership {
  width: 450px;
}

#mdl_cancelmembership .modal-header {
  color: #2ea3a6;
}

#mdl_cancelmembership .modal-header img {
  height: 26px;
  margin: -10px 8px -7px -14px;
}

#mdl_cancelmembership .modal-content {
  padding: 24px;
  line-height: 20px;
}

/*  MODAL: THANK YOU -------------------------------------------------------------------------------------------------*/

#mdl_thankyou {
  width: 450px;
}

#mdl_thankyou .modal-header {
  color: #2ea3a6;
}

#mdl_thankyou .modal-header img {
  height: 26px;
  margin: -10px 8px -7px -14px;
}

#mdl_thankyou .modal-content {
  padding: 24px;
  line-height: 20px;
}

@media only screen and (max-width: 950px) {
  .tab_page_dashboard_content_wrapper {
    grid-template-columns: 1fr 1fr;
  }

  #mdl_updatecreditcard {
    width: 400px;
  }
}

@media only screen and (max-width: 675px) {
  .tab_page_dashboard_footer {
    display: none;
  }

  .tab_page_account_wrapper {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .tab_page_dashboard_content_wrapper {
    grid-template-columns: 1fr;
  }

  #mdl_updatecreditcard {
    width: 400px;
  }
}
