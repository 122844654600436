
.mcb_module_header {
    padding: 70px 32px;
    background-color: #ee8080;
    color: #ffffff;
    border: none;
}

.mcb_module_header_stripe {
    height: 40px;
    background-image: url("/icons/bg strip 1000px (ee8080).svg");
    padding: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #ee8080;
}

.mcb_module_description {
    text-align: left;
}

.module_title {
    font-weight: 700;
    font-size: 52px;
    line-height: 56px;
    font-family: "Heebo", sans-serif;
}

#page_assets {
    border-bottom: 1px solid #e3e3e3;
}

#page_content {
    border-bottom: 1px solid #e3e3e3;
}

#assets {
    float: left;
    width: 100%;
    padding: 48px 0px 16px 0px;
}


.mcb_blocks {
    border-bottom: none;
    background-color: #f1f0f3;
}

.blocks_wrapper {
    float: left;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}


.block {
    background-color: #ffffff;
    border-radius: 8px;
    display: grid;
    grid-template-column: 1fr;
    cursor: pointer;
}


.block_img.paid {
    opacity: .5;
}

.block_img img {
    width: 100%;
    margin-bottom: -6px;
    border-radius: 10px 10px 0 0;
    padding: 6px
}

.block_description_wrapper {
    display: grid;
    grid-template-columns: 70px auto;
    height: 81px;
}

.block_description_icon {
    width: 70px;
    padding: 12px 16px;
    display: flex;
}

.block_description_icon img {
    width: 100%;
    height: 100%;
}

.block_description_title.paid {
    color: #bcb6c3;
}

.block_description {
    padding: 19px 16px 16px 0px;
    display: grid;
    text-align: left;
}

.block_description_title {
    color: #5e637c;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    align-self: center;
}

.block_description_topics {
    color: #bcb6c3;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}


.block_access {
    height: 44px;
    border-top: 2px solid #f1f0f3;
    color: #bcb6c3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0 0 8px 8px;
}

.block_access.free {
    border-top: 2px solid #ee8080;
    background-color: #ee8080;
}

.block_access_wrapper {
    font-weight: 500;
    font-size: 14px;
    padding: 0 0 0 16px;
    display: flex;
}

.block_access_img {
    width: 20px;
    display: flex;
    margin-right: 12px;
}

.block_access_img img {
    width: 100%;
    height: 100%;
}

.block_access_text.free {
    color: #ffffff;
}

@media only screen and (max-width: 950px) {

    .blocks_wrapper {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 768px) {

    .mcb_module_header {
        padding: 80px 32px;
    }

    .module_title {
        font-size: 40px;
        line-height: 42px;
    }

    .block {
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(min-content, max-content);
        grid-template-rows: 1fr 80px;
    }

    .block_description {
        border-right: none;
        padding: 0px 16px 16px 16px;
    }

    .block_description_title {
        line-height: 21px;
        font-size: 17px;
    }


    .block_counts_wrapper {
        text-align: left;
    }

    .whatistapcircle {
        grid-template-columns: 1fr;
        grid-template-rows: 200px 1fr;
        text-align: center;
    }

    .whatistapcircle_text {
        display: flex;
        align-items: unset;
        padding-left: 0px;
    }

}

@media only screen and (max-width: 615px) {

    .blocks_wrapper {
        grid-template-columns: 1fr;
    }
}


@media only screen and (max-width: 560px) {

    /*
    .blocks_wrapper {
        grid-template-columns: 1fr;
    }

    .block {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: unset;
        grid-template-rows: unset;
    }

    .block_description {
        padding: 16px 16px 16px 0px;
        text-align: left;
    }
    */

}


@media only screen and (max-width: 500px) {

    /*.mcb_module_header {
        padding: 80px 32px 0px;
    }

    .module_title {
        font-size: 32px;
        line-height: 36px;
    }*/

}


@media only screen and (max-width: 450px) {

    /*.mcb_module_header {
        padding: 72px 32px;
    }

    .block {
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(min-content, max-content);
        grid-template-rows: 1fr 80px;
    }

    .block_description {
        padding: 0px 16px 16px 16px;
        text-align: center;
        border-right: none;
    }*/


}