.homeheader_block_A {
  background-image: url("/icons/homebanner-B.png");
  background-position: right;
  background-color: #424863;
  height: 138px;
}

.homeheader_block_D {
  height: 321px;
  background-image: url("/icons/homebanner-D.png");
  background-position: right;
  background-color: #424863;
}

.homeheader_block_L {
  height: 178px;
  background-color: #35a3a5;
}

.homeheader_wrapper_center {
  width: 1000px;
  display: flex;
  flex-direction: column;
}

.homeheader_block_B {
  background-image: url("/icons/homebanner-B.png");
  background-color: #424863;
  height: 138px;
}

.homeheader_wrapper_center_EFGHIJ {
  display: flex;
  flex-direction: row;
}

.homeheader_block_E {
  background-image: url("/icons/homebanner-E.png");
  background-color: #424863;
  width: 33%;
  height: 265px;
}

.homeheader_block_F {
  background-color: #424863;
  flex-grow: 1;
  height: 265px;
}

.homeheader_block_G {
  background-image: url("/icons/homebanner-G.png");
  background-position: center;
  background-color: #424863;
  width: 33%;
  height: 265px;
}

.homeheader_block_H {
  height: 56px;
  background-image: url("/icons/homebanner-H.png");
  background-color: #424863;
}

.homeheader_block_I {
  height: 56px;
  background-image: url("/icons/homebanner-H.png");
  background-position: center;
  background-color: #424863;
}

.homeheader_block_J {
  height: 56px;
  background-image: url("/icons/homebanner-H.png");
  background-position: center;
  background-color: #424863;
}

.homeheader_block_M {
  background-image: url("/icons/homebanner-M.png");
  height: 178px;
  background-color: #35a3a5;
}

.homeheader_wrapper_right {
  width: calc((100% - 1000px) / 2);
  display: flex;
  flex-direction: column;
}

.homeheader_block_C {
  background-image: url("/icons/homebanner-B.png");
  background-color: #424863;
  height: 138px;
}

.homeheader_block_K {
  height: 321px;
  background-image: url("/icons/homebanner-K.png");
  background-position: left;
  background-color: #424863;
}

.homeheader_block_N {
  height: 178px;
  background-color: #35a3a5;
}

/* landingpages ----------------------------------------------------------------------------------------------------- */

.mcb_landingpages_intro {
  color: #363c5b;
  background-color: #f1f0f3;
  padding-bottom: 0;
}

.mcb_landingpages_intro p {
  font-weight: 500 !important;
  margin-bottom: 8px;
}

.landingpages_intro_wrapper {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 24px;
}

.landingpages_intro_pullout {
  color: #eb6060;
}

.mcb_landingpages {
  padding-top: 8px;
  background-color: #f1f0f3;
}

.landingpages_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.landingpages_item {
  justify-content: center;
  padding: 16px 16px 2px 16px;
  /* border-radius: 8px; */
  /* background-color: #ffffff; */
}

.landingpages_item_icon {
}

.landingpages_item_icon img {
  width: 100%;
  margin-bottom: -6px;
  border-radius: 10px 10px 0 0;
  padding: 6px;
}

.landingpages_item_title {
  color: #5e637c;
  font-weight: 700;
  font-size: 15px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 283px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  /* line-height: 18px;
  align-self: center;
  padding-bottom: 16px; */
}

/* stats ------------------------------------------------------------------------------------------------------------ */

.mcb_home_stats {
}

.home_stats_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 48px;
}

.home_stats_item {
  border-radius: 8px;
}

#home_stats_item_modules {
  color: #eb6060;
}

#home_stats_item_blocks {
  color: #58b5b7;
}

#home_stats_item_topics {
  color: #cfaea9;
}

#home_stats_item_resources {
  color: #5e637c;
}

.home_stats_item_icon {
  height: 80px;
}

.home_stats_item_icon img {
  width: 80px;
}

#home_stats_item_resources img {
  height: 75px;
}

.home_stats_item_number {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  padding: 16px 0px 12px 0px;
}

.home_stats_item_text {
  margin: 0px -16px 0px -16px;
  padding: 0px 16px;
  border-top: 2px solid #ffffff;
  color: #bcb6c3;
  font-size: 14px;
  font-weight: 400;
}

.home_stats_text {
  padding-top: 48px;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #363c5b;
}

.home_stats_text a {
  color: #58b5b7;
  text-decoration: underline;
}

/* sign up ---------------------------------------------------------------------------------------------------------- */

.mcb_home_signup {
  background-color: #f19c9c;
  background-image: url("/icons/bg pattern 1000px (f19c9c).svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.home_signup_wrapper {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 2px;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
}

.home_signup_block_1 {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px 8px 0px 0px;
}

.home_signup_block_2 {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px 8px 0px 0px;
}

.home_signup_block_2_wrapper {
  display: grid;
  grid-template-columns: 148px 1fr;
  grid-column-gap: 24px;
  color: #5e637c;
  line-height: 24px;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  align-items: center;
}

.home_signup_block_2_img {
  padding: 24px;
}

.home_signup_block_2_img img {
  width: 100%;
  margin-bottom: -5px;
}

.home_signup_block_3 {
  background-color: #ffffff;
  padding: 32px 24px 32px 24px;
  border-radius: 0px 0px 8px 8px;
}

.home_signup_block_3_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  color: #5e637c;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  height: 100%;
  align-items: center;
}

.home_signup_block_4 {
  background-color: #ffffff;
  color: #bcb6c3;
  padding: 16px;
  border-radius: 0px 0px 8px 8px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
}

.home_signup_block_4_wrapper {
  padding: 16px 0px 0px 0px;
}

.home_signup_block_4_title {
  font-size: 14px;
  font-weight: 500;
  color: #5e637c;
}

.home_signup_block_4_cta_button_wrapper {
  padding: 16px 0px 16px 0px;
}

.home_signup_block_4_cta_button {
  padding: 0px 36px !important;
  height: 52px !important;
  display: flex;
  justify-content: center;
  text-decoration: none !important;
}

.home_signup_block_4 a {
  color: #eb6060;
  text-decoration: underline;
}

.home_signup_block {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 0;
}

.home_signup_block_header {
  background-color: #5e637c;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  padding: 24px;
  border-radius: 7px 7px 0px 0px;
}

.home_signup_block_content {
  text-align: left;
  padding: 24px;
}

#home_signup_block_content_features .home_signup_block_content {
  padding-top: 40px;
}

.home_signup_block_content_header {
  color: #eb6060;
  font-size: 14px;
  font-weight: 500;
  padding: 16px 0px;
}

.home_signup_block_content_header:first-of-type {
  padding-top: 0px;
}

.home_signup_block_content_text {
  color: #a7a1ad;
  font-size: 14px;
  font-weight: 400;
}

#home_signup_block_content_features .home_signup_block_content_text {
  padding-left: 12px;
  padding-bottom: 4px;
}

.home_signup_cta_price_wrapper_outer {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
}

.home_signup_cta_price_wrapper {
  color: #eb6060;
  display: grid;
  grid-template-columns: auto auto auto auto;
  height: 68px;
}

.home_signup_cta_price_currency_icon {
  text-align: right;
  font-size: 32px;
  font-weight: 600;
  line-height: 46px;
}

.home_signup_cta_price_price {
  font-size: 64px;
  font-weight: 600;
  line-height: 64px;
  letter-spacing: -2px;
}

.home_signup_cta_price_currency {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  line-height: 94px;
  color: #bcb6c3;
}

.home_signup_cta_price_duration {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  line-height: 94px;
}

.home_signup_block_content_price_features {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0px 4% 0px 4%;
}

.home_signup_block_content_price_features_item {
  color: #5e637c;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: 13px;
}

.home_signup_block_content_price_features_item:first-of-type {
  border-right: 1px solid #5e637c;
}

.home_signup_block_content_price_features_item:last-of-type {
  border-left: 1px solid #5e637c;
}

.home_signup_block_content_text_coffee {
  display: flex;
  height: 56px;
  width: 56px;
  justify-content: center;
  margin: 0 auto;
}

.home_signup_block_content_text_coffee img {
  height: 100%;
  background-color: #ffffff;
  padding: 4px;
}

#home_signup_block_content_price .home_signup_block_content_text {
  border-top: 2px solid #bcb6c3;
  border-bottom: 2px solid #bcb6c3;
  padding: 38px 0px 38px 0px;
  margin-top: -27px;
}

.home_signup_cta_button {
  padding: 32px 0px 8px 0px;
  display: flex;
  justify-content: center;
}

/* testimonials ----------------------------------------------------------------------------------------------------- */

.mcb_home_saying {
  background-color: #f1f0f3;
}

.home_saying_header {
  color: #363c5b;
  font-weight: 500;
  font-size: 28px;
  padding: 16px;
}

.home_saying_item_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding-top: 16px;
}

.home_saying_item {
  background-color: #ffffff;
  border-radius: 8px;
  text-align: left;
  padding: 32px 24px;
  font-size: 14px;
  font-weight: 400;
  color: #292d44;
}

.home_saying_item_header {
  padding-top: 8px;
  color: #d4d1d8;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
}

/*  who is tap ------------------------------------------------------------------------------------------------------ */

.mcb_home_whoistap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.home_whoistap_1 {
  background-color: #58b5b7;
  color: #ffffff;
  line-height: 24px;
  font-size: 12px;
  font-weight: 500;
  padding: 24px;
  text-align: left;
  align-items: center;
  display: flex;
}

.home_whoistap_2 {
  background-image: url(/icons/home_whoistap.jpg);
  background-size: cover;
}

.home_whoistap_3 {
  background-color: #5e637c;
  align-items: center;
  justify-content: center;
  display: flex;
}

.home_whoistap_3 img {
  height: 80px;
}

.whoistap_left {
  background-color: #80c6c8;
  padding: 24px;
  border-radius: 8px;
}

.whoistap_left_quote {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 24px;
  color: #5e637c;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  align-self: center;
}

.whoistap_left_signin {
  color: #7d7d7d;
  font-size: 14px;
  font-weight: 400;
}

.whoistap_left_signin a {
  color: #7d7d7d;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}

.whoistap_left_logo {
  width: 150px;
  padding-top: 32px;
  margin: 0 auto;
}

.whoistap_left_logo img {
  width: 100%;
}

.whoistap_right {
  background-color: #f1f0f3;
  border-radius: 8px;
  display: grid;
  grid-template-rows: 1fr 50px 70px 50px;
}

.whoistap_right_quotewrapper {
  padding: 24px;
}

.whoistap_right_quote {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 24px;
  color: #5e637c;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  align-self: center;
}

.whoistap_right_keyword {
  background-color: #eb6060;
}

.whoistap_right_cta {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.whoistap_right_logo {
  background-color: #5e637c;
  padding-top: 12px;
}

.whoistap_right_logo img {
  width: 200px;
}

.home_whoistap_logo {
  padding-top: 48px;
  justify-content: center;
}

.home_whoistap_logo img {
  width: 400px;
}

.home_whoistap_keyword_wrapper {
  display: grid;
  grid-template-columns: auto 40px auto 40px auto 40px auto;
  justify-content: center;
  padding-top: 12px;
}

.home_whoistap_keyword {
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
}

.home_whoistap_keyword_separator {
  color: #ffffff;
  font-weight: 700;
  font-size: 32px;
  margin-top: -7px;
}

.home_whoistap_quote {
  background-color: #f1f0f3;
  border-radius: 7px;
  padding: 32px;
  color: #363c5b;
}

.home_whoistap_cta_button {
  padding: 48px;
}

/* ------------------------------------------------------------------------------------------------------------------ */
@media only screen and (max-width: 1015px) {
  .homeheader_wrapper_left {
    display: none;
  }

  .homeheader_wrapper_center {
    width: 100%;
  }

  .homeheader_wrapper_right {
    display: none;
  }

  .homeheader_text {
    width: 90%;
  }

  .homeheader_block_E {
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .homeheader_block_G {
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .homeheader_block_M {
    background-position: center;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */
@media only screen and (max-width: 768px) {
  .home_features_wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .home_signup_wrapper {
    grid-template-columns: 1fr;
  }

  .home_saying_item_wrapper {
    grid-template-columns: 1fr;
  }

  .home_stats_wrapper {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 48px;
  }

  .landingpages_wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .home_signup_block_1 {
    border-radius: 8px;
  }

  .home_signup_block_2 {
    border-radius: 8px;
  }

  .home_signup_block_3 {
    border-radius: 8px;
  }

  .home_signup_block_4 {
    border-radius: 8px;
  }

  .mcb_home_whoistap {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding: 3.5%;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */
@media only screen and (max-width: 666px) {
  .homeheader_wrapper_center_EH {
    width: 50%;
  }

  .homeheader_text {
    padding-top: 128px;
  }

  .homeheader_block_E {
    background-image: unset;
  }

  .homeheader_block_G {
    background-image: unset;
  }

  .homeheader_wrapper_center_FI {
    display: none;
  }

  .homeheader_wrapper_center_GJ {
    width: 50%;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */
@media only screen and (max-width: 600px) {
  .home_pillars_wrapper {
    grid-template-columns: 260px;
  }

  .home_whoistap_keyword_wrapper {
    grid-template-columns: auto 20px auto 20px auto 20px auto;
  }

  .home_whoistap_keyword {
    font-size: 16px;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */
@media only screen and (max-width: 500px) {
  .mcb_home_logo img {
    width: 74%;
    margin-top: 28px;
  }

  .home_features_wrapper {
    grid-template-columns: 1fr;
  }

  .landingpages_wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .home_stats_wrapper {
    grid-template-columns: 1fr;
  }

  .home_signup_block_content {
    padding: 0;
  }

  .home_whoistap_logo img {
    width: 300px;
  }
}

/* ------------------------------------------------------------------------------------------------------------------ */
@media only screen and (max-width: 450px) {
  .landingpages_wrapper {
    grid-template-columns: 1fr;
  }
}

.homeheader_wrapper {
  width: 100%;
}

.homeheader_text_wrapper {
  width: 100%;
  z-index: 1;
  position: absolute;
}

.homeheader {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.homeheader_text {
  width: 510px;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
  padding-top: 96px;
}

.homeheader_text_title {
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 32px;
}

.homeheader_text_subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.homeheader_wrapper_left {
  width: calc((100% - 1000px) / 2);
  display: flex;
  flex-direction: column;
}

/* NEW CSS 

.climb {
  transform: translatey(0px);
  -webkit-animation: float 6s ease-in-out infinite;
  animation: float 6s ease-in-out infinite;
}

@-webkit-keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.textcontainer {
  display: flex;
  flex-direction: column;
  
  justify-content: center;
}
.lineheight13 {
  line-height: 1.3;
}
.brand-red {
  color: #eb6060;
}
.brand-green {
  color: #2ea3a6;
}
@media only screen and (min-width: 1200px) {
  .hero-updated {
    min-height: 858px;
  }
  .mainboxes {
    min-height: 1031px;
  }
  .homecard {
    height: 350px;
    width: 350px;
    border: 1px solid #2ea3a6;
    align-self: center;
  }
  .cardcontainer {
    padding-left: 15%;
    padding-right: 15%;
    grid-template-columns: repeat(3, 1fr);
    justify-self: center;
    row-gap: 80px;
  }

  .littlefloat {
    position: absolute;
    left: 0;
    top: 0;
    width: 862px;
    height: 553px;
    mix-blend-mode: overlay;
  }
}

/* logo ------------------------------------------------------------------------------------------------------------- */

.mcb_home_logo {
  background-color: #f1f0f3;
}

.mcb_home_logo img {
  width: 60%;
  margin-top: 45px;
}

/* header --------------------------------------------------------------------------------------------------------- */

/* NEWCSS 2*/

.climb {
  transform: translatey(0px);
  -webkit-animation: float 6s ease-in-out infinite;
  animation: float 6s ease-in-out infinite;
}

@-webkit-keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.littlefloat {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: overlay;
}
.textcontainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}
.lineheight13 {
  line-height: 1.3;
}
.brand-red {
  color: #eb6060;
}
.brand-green {
  color: #2ea3a6;
}
@media only screen and (min-width: 1400px) {
  .hero-updated {
    min-height: 858px;
  }
  .mainboxes {
    min-height: 1031px;
  }
  .homecard {
    height: 350px;
    width: 350px;
    border: 1px solid #2ea3a6;
    align-self: center;
    margin-bottom: 60px;
  }
  .cardcontainer {
    /* padding-left:15%;
    padding-right:15%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.cardcontainer {
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.homecard {
  height: 350px;
  width: 350px;
  border: 1px solid #2ea3a6;
  align-self: center;
  margin-bottom: 60px;
}
@media only screen and (max-width: 1015px) {
  .hero_after {
    padding: 10% 20%;
  }
}

@media only screen and (max-width: 749px) {
  .hero_after {
    padding: 10% 10%;
  }
}

@media only screen and (max-width: 640px) {
  .textcontainer {
    padding: 20px 50px;
  }
  .textcontainer h1 {
    font-size: 2rem;
  }
}

.pricing_home_updated {
  margin-top: -250px;
  padding-top: 300px;
}

.pricing-cards {
  width: 400px;
}
.pricingfeature {
  margin-bottom: 15px;
  font-size: 1rem;
}
.pricingfeature span {
  font-size: 0.9rem;
}
.pricingfeature svg {
  fill: #2ea3a6;
  display: inline-block;
  margin-right: 5px;
}
.money {
  font-size: 0.5em;
  vertical-align: super;
}
.priceguy {
  width: 80%;
  height: auto;
}
.brand-green-bg {
  background-color: #2ea3a6;
}

.brand-green-link:hover {
  color: #248284;
}

.brand-dblue-bg {
  background-color: #2c314d;
}

.accountabilty-banner-logo {
  width: 239px;
  max-height: 120px;
}

@media only screen and (max-width: 640px) {
  .pricing_home_updated {
    margin-top: -250px;
    padding-top: 200px;
  }
}

@media only screen and (min-width: 768px) {
  .container-padding {
    padding-left: 5%;
    padding-right: 5%;
  }
}
