#page_signup_card {
  display: flex;
  /* grid-template-columns: 3fr 1fr;
  grid-column-gap: 16px; */
  padding: 0px;
}

.signup_new_card {
  min-width: 425px;
}

.page_signup_1_card {
  border-radius: 8px;
  background-color: #fff;
  padding: 24px 0;
}

.page_signup_1 .page_signup_header {
  padding-left: 12px;
  padding-bottom: 0;
}

.page_signup_2_card {
  background-color: #f5f5f5;
  width: 480px;
  height: 687px;
  padding: 24px;
  position: absolute;
  right: 0;
  top: 120px;
}

.page_signup_header {
  font-weight: 700;
  font-size: 24px;
  line-height: 18px;
  padding-bottom: 24px;
  text-align: left;
}

.page_signup_2_wrapper_card {
  display: grid;
  grid-template-columns: 300px;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
}

.page_signup_2_item_header {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #313131;
  opacity: 1;
  text-align: left;
  padding-bottom: 8px;
  letter-spacing: 0px;
}

.page_signup_2_item_text {
  color: #bcb6c3;
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
.signup {
  width: 100%;
  margin: 32px auto;
}

.passwordnote {
  margin-top: 32px;
  margin-bottom: 16px;
  color: #bcb6c3;
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.passwordvalidation {
  margin-bottom: 32px;
}

.invoicetotal {
  display: grid;
  grid-template-columns: auto 140px;
  text-align: right;
  margin: 28px 0;
  text-align: left;
  padding: 12px 0;
  letter-spacing: 0.32px;
  color: #313131;
  font-size: 16px;
  padding: 10px 5px;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.invoicetotal_text {
  font-weight: 500;
}

@media only screen and (max-width: 950px) {
  #page_signup {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }

  .page_signup_2_wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .page_signup_2 {
    position: relative;
  }

  .signup_new_card {
    min-width: 350px;
  }
}

@media only screen and (max-width: 615px) {
  .page_signup_2_wrapper {
    grid-template-columns: 1fr;
  }

  .page_signup_2 {
    position: relative;
  }

  .signup_new_card {
    min-width: 350px;
  }
}

.feature-check-mark {
  height: 24px;
  width: 24px;
  margin-right: 16px;
}
