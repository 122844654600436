
.mcb_tool_header {
    display: grid;
    /*
    grid-template-columns: 150px auto;
    */
}

.tool_header_icon {
    width: 120px;
}

.tool_header_icon img {
    height: 100%;
    width: 100%;
}

.tool_header_wrapper {
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.tool_header_title {
    font-weight: 700;
    font-size: 52px;
    line-height: 60px;
    color: #5e637c;
    text-align: left;
}

.tool_header_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-left: auto;
    margin-right: auto;
    color: #363c5b;
    text-align: left;
    padding: 0 16px;
}

.myjournal_clickable {
    cursor: pointer;
}